// Media
.img-responsive {
  display: block;
  height: auto;
  max-width: 100%;
}
.video-responsive {
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%; /* Default 16:9, you can calculate this value by dividing 9 by 16 */
  padding-top: 3rem;
  position: relative;

  iframe,
  object,
  embed {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  video {
    height: auto;
    max-width: 100%;
    width: 100%;
  }
}
.video-responsive-4-3 {
  padding-bottom: 75%; /* 4:3 */
}
