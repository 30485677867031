// Position
.clearfix {
  .clearfix();
}
.float-left {
  float: left !important;
}
.float-right {
  float: right !important;
}

.rel {
  position: relative;
}
.abs {
  position: absolute;
}
.fixed {
  position: fixed;
}
.centered {
  display: block;
  float: none;
  margin-left: auto;
  margin-right: auto;
}

.mt-10 {
  margin-top: 1rem;
}
.mt-20 {
  margin-top: 2rem;
}
.mt-30 {
  margin-top: 3rem;
}
.mt-40 {
  margin-top: 4rem;
}
.mt-50 {
  margin-top: 5rem;
}
.mr-10 {
  margin-right: 1rem;
}
.mb-10 {
  margin-bottom: 1rem;
}
.ml-10 {
  margin-left: 1rem;
}
.mt-5 {
  margin-top: .5rem;
}
.mr-5 {
  margin-right: .5rem;
}
.mb-5 {
  margin-bottom: .5rem;
}
.ml-5 {
  margin-left: .5rem;
}

.pt-10 {
  padding-top: 1rem;
}
.pr-10 {
  padding-right: 1rem;
}
.pb-10 {
  padding-bottom: 1rem;
}
.pl-10 {
  padding-left: 1rem;
}
.pt-5 {
  padding-top: .5rem;
}
.pr-5 {
  padding-right: .5rem;
}
.pb-5 {
  padding-bottom: .5rem;
}
.pl-5 {
  padding-left: .5rem;
}

// Display
.block {
  display: block;
}
.inline {
  display: inline;
}
.inline-block {
  display: inline-block;
}
.flex {
  display: flex;
}
.inline-flex {
  display: inline-flex;
}
.hide {
  display: none !important;
}
.visible {
  visibility: visible;
}
.invisible {
  visibility: hidden;
}
.text-hide {
  background: transparent;
  border: 0;
  color: transparent;
  font: 0/0 a;
  text-shadow: none;
}
// Text
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-justify {
  text-align: justify;
}

.text-lowercase {
  text-transform: lowercase;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-capitalize {
  text-transform: capitalize;
}

.text-normal {
  font-weight: normal;
}
.text-bold {
  font-weight: bold;
}
.text-italic {
  font-style: italic;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text-clip {
  overflow: hidden;
  text-overflow: clip;
  white-space: nowrap;
}
.text-break {
  hyphens: auto;
  word-break: break-word;
  word-wrap: break-word;
}
.hand {
  cursor: pointer;
}

// Shadow
.shadow {
  box-shadow: 0 .1rem .4rem rgba(0, 0, 0, .3);
}
.light-shadow {
  box-shadow: 0 .1rem .2rem rgba(0, 0, 0, .15);
}

// Shapes
.rounded {
  border-radius: .3rem;
}
.circle {
  border-radius: 50%;
}
// Divider
.divider {
  border-bottom: .1rem solid @core-border-color;
  display: block;
  margin: .5rem;
}

// Loading
.loading {
  color: transparent !important;
  min-height: 1.6rem;
  pointer-events: none;
  position: relative;

  &::after {
    animation: loading 500ms infinite linear;
    border: .2rem solid @core-color;
    border-radius: .8rem;
    border-right-color: transparent;
    border-top-color: transparent;
    content: "";
    display: block;
    height: 1.6rem;
    left: 50%;
    margin-left: -.8rem;
    margin-top: -.8rem;
    position: absolute;
    top: 50%;
    width: 1.6rem;
  }
}
