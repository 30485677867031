// Layout
.container {
  margin-left: auto;
  margin-right: auto;
  padding-left: @layout-gutter;
  padding-right: @layout-gutter;
  width: 100%;
  &:extend(.clearfix all);
}
// Grid sizes
@media screen and (min-width: (960px + 2 * @html-font-size * @layout-gutter)) {
  .grid-960 {
    width: 96rem + 2 * @layout-gutter;
  }
}
@media screen and (min-width: (480px + 2 * @html-font-size * @layout-gutter)) {
  .grid-480 {
    width: 48rem + 2 * @layout-gutter;
  }
}

// Responsive grid system
.columns {
  display: flex;
  margin-left: -@layout-gutter;
  margin-right: -@layout-gutter;
}
.column {
  flex: 1;
  padding: @layout-gutter;

  &.col-12,
  &.col-11,
  &.col-10,
  &.col-9,
  &.col-8,
  &.col-7,
  &.col-6,
  &.col-5,
  &.col-4,
  &.col-3,
  &.col-2,
  &.col-1 {
    flex: none;
  }
}
.col-12 {
  width: 100%;
}
.col-11 {
  width: 91.66666667%;
}
.col-10 {
  width: 83.33333333%;
}
.col-9 {
  width: 75%;
}
.col-8 {
  width: 66.66666667%;
}
.col-7 {
  width: 58.33333333%;
}
.col-6 {
  width: 50%;
}
.col-5 {
  width: 41.66666667%;
}
.col-4 {
  width: 33.33333333%;
}
.col-3 {
  width: 25%;
}
.col-2 {
  width: 16.66666667%;
}
.col-1 {
  width: 8.33333333%;
}
@media screen and (min-width: (@size-xs + 1px)) {
  .col-xs-12,
  .col-xs-11,
  .col-xs-10,
  .col-xs-9,
  .col-xs-8,
  .col-xs-7,
  .col-xs-6,
  .col-xs-5,
  .col-xs-4,
  .col-xs-3,
  .col-xs-2,
  .col-xs-1 {
    flex: none;
  }
  .col-xs-12 {
    width: 100%;
  }
  .col-xs-11 {
    width: 91.66666667%;
  }
  .col-xs-10 {
    width: 83.33333333%;
  }
  .col-xs-9 {
    width: 75%;
  }
  .col-xs-8 {
    width: 66.66666667%;
  }
  .col-xs-7 {
    width: 58.33333333%;
  }
  .col-xs-6 {
    width: 50%;
  }
  .col-xs-5 {
    width: 41.66666667%;
  }
  .col-xs-4 {
    width: 33.33333333%;
  }
  .col-xs-3 {
    width: 25%;
  }
  .col-xs-2 {
    width: 16.66666667%;
  }
  .col-xs-1 {
    width: 8.33333333%;
  }
}
@media screen and (min-width: (@size-sm + 1px)) {
  .col-sm-12,
  .col-sm-11,
  .col-sm-10,
  .col-sm-9,
  .col-sm-8,
  .col-sm-7,
  .col-sm-6,
  .col-sm-5,
  .col-sm-4,
  .col-sm-3,
  .col-sm-2,
  .col-sm-1 {
    flex: none;
  }
  .col-sm-12 {
    width: 100%;
  }
  .col-sm-11 {
    width: 91.66666667%;
  }
  .col-sm-10 {
    width: 83.33333333%;
  }
  .col-sm-9 {
    width: 75%;
  }
  .col-sm-8 {
    width: 66.66666667%;
  }
  .col-sm-7 {
    width: 58.33333333%;
  }
  .col-sm-6 {
    width: 50%;
  }
  .col-sm-5 {
    width: 41.66666667%;
  }
  .col-sm-4 {
    width: 33.33333333%;
  }
  .col-sm-3 {
    width: 25%;
  }
  .col-sm-2 {
    width: 16.66666667%;
  }
  .col-sm-1 {
    width: 8.33333333%;
  }
}
@media screen and (min-width: (@size-md + 1px)) {
  .col-md-12,
  .col-md-11,
  .col-md-10,
  .col-md-9,
  .col-md-8,
  .col-md-7,
  .col-md-6,
  .col-md-5,
  .col-md-4,
  .col-md-3,
  .col-md-2,
  .col-md-1 {
    flex: none;
  }
  .col-md-12 {
    width: 100%;
  }
  .col-md-11 {
    width: 91.66666667%;
  }
  .col-md-10 {
    width: 83.33333333%;
  }
  .col-md-9 {
    width: 75%;
  }
  .col-md-8 {
    width: 66.66666667%;
  }
  .col-md-7 {
    width: 58.33333333%;
  }
  .col-md-6 {
    width: 50%;
  }
  .col-md-5 {
    width: 41.66666667%;
  }
  .col-md-4 {
    width: 33.33333333%;
  }
  .col-md-3 {
    width: 25%;
  }
  .col-md-2 {
    width: 16.66666667%;
  }
  .col-md-1 {
    width: 8.33333333%;
  }
}
@media screen and (min-width: (@size-md + 1px)) {
  .form-horizontal {
    padding: 1rem;

    .form-group {
      display: flex;
    }
    .form-label {
      padding: .8rem .4rem;
      margin-bottom: 0;
    }
    .form-checkbox,
    .form-radio {
      margin: .5rem 0;
    }
  }
}
@media screen and (max-width: @size-xs) {
  .columns {
    display: block;
    &:extend(.clearfix all);

    .column {
      width: 100%;
    }
  }
}

// Responsive breakpoint system
@media screen and (max-width: @size-xs) {
  .hide-xs {
    display: none !important;
  }
}
@media screen and (max-width: @size-sm) {
  .hide-sm {
    display: none !important;
  }
}
@media screen and (max-width: @size-md) {
  .hide-md {
    display: none !important;
  }
}
@media screen and (max-width: @size-lg) {
  .hide-lg {
    display: none !important;
  }
}
@media screen and (max-width: @size-xl) {
  .hide-xl {
    display: none !important;
  }
}
