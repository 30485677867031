// Toasts
.toast {
  background: @core-border-color;
  border: .1rem solid darken(@core-border-color, 2%);
  border-radius: .3rem;
  color: #666;
  display: block;
  padding: 1.4rem;
  width: 100%;

  &.toast-primary {
    .toast-variant(@core-color);
  }

  &.toast-success {
    .toast-variant(@control-color-success);
  }

  &.toast-danger {
    .toast-variant(@control-color-danger);
  }

  &.toast-primary,
  &.toast-success,
  &.toast-danger {
    .btn-clear {
      color: #fff;
    }
  }
}
