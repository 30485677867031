// Animations
@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes slide-down {
  0% {
    margin-top: -3rem;
    opacity: 0;
  }
  100% {
    margin-top: 0;
    opacity: 1;
  }
}
