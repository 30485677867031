// Labels
.label {
  background: @core-border-color;
  color: lighten(@body-font-color, 20%);
  .label-base();
  white-space: nowrap;

  &.label-primary {
    .toast-variant(@core-color);
  }
}
