html, body {
    height: 100%;
}

// Fonts
.quattrocento {
    font-family: 'Quattrocento';
    color: @colorEko;
    font-weight: bold;
}

.do-print {
    display: none;
}

// Layout
.header {
    max-width: 100%;
    height: 300px;
    background: url('/img/baraeko-header.jpg');
    background-size: cover;
    background-position: 50% 50%;
    padding-top: 70px;
    .header--img {
        max-height: 150px;
        max-width: 57%;
    }
    @media screen and (max-width: (@size-sm)) {
        padding-top: 44px;
        background-size: 200%;
        height: 150px;
    }
}

.ie-warning {
    display: none;
}

.info-columns {
    .column {
        position: relative;
    }
    h3 {
        margin-bottom: -3rem;
    }
    p {
        color: darken(#95a5a6, 20%);
    }
    img {
        max-width: 50%;
        &.smaller {
            max-width: 30%;
        }
        &.positioned {
            vertical-align: bottom;
            border: 0;
            position: absolute;
            bottom: 15px;
            left: 50%;
            &.w20 {
                max-width: 20%;
                margin-left: -10%;
            }
            &.w30 {
                max-width: 30%;
                margin-left: -15%;
            }
            &.w40 {
                max-width: 40%;
                margin-left: -20%;
            }
            &.w50 {
                max-width: 50%;
                margin-left: -25%;
            }
            &.w60 {
                max-width: 60%;
                margin-left: -30%;
            }
            &.w70 {
                max-width: 70%;
                margin-left: -35%;
            }
            &.w80 {
                max-width: 80%;
                margin-left: -40%;
            }
        }
    }
}

html.touch {
    .startpage-nav {
        ul {
            li {
                a {
                    &:hover {
                        background-color: #2b2b2e;
                        color: #fff;
                    }
                }
            }
        }
    }
}

ul {
    &.compact {
        margin-top: .7rem;
        margin-bottom: .7rem;
        margin-left: 1.5rem;
        li {
            margin: .3rem 0;
        }
    }
}

.startpage-nav {
    background-color: #2b2b2e;
    ul {
        list-style: none;
        display: block;
        margin: 0;
        text-align: center;
        li {
            display: inline-block;
            margin: 0;
            @media screen and (max-width: (@size-sm + 326px)) {
                display: block;
            }
            a {
                padding: 1.2rem;
                display: block;
                color: #fff;
                font-size: 15px;
                text-decoration: none;
                -webkit-font-smoothing: antialiased;
                font-smoothing: antialiased;
                font-weight: bold;
                &:hover {
                    background-color: #fff;
                    color: #333;
                }
            }
        }
    }
    @media screen and (max-width: (@size-sm + 326px)) {
        .container {
            // padding: 0;
        }
        .columns {
            padding: 0;
        }
    }
    .menu-container {
        display: none;
        @media screen and (max-width: (@size-sm + 326px)) {
            display: block;
        }
        .active-icon {
            display: inline-block;
        }
        .inactive-icon {
            display: none;
        }
        &.active, &.active:hover {
            a {
                background-color: #fff !important;
                color: #333 !important;
            }
            .active-icon {
                display: none;
            }
            .inactive-icon {
                display: inline-block;
            }
        }
    }
    .options {
        @media screen and (max-width: (@size-sm + 326px)) {
            display: none;
            &.active {
                display: inline-block;
                width: 100%;
            }
            a {
                width: 100%;
                padding: 1.7rem 0;
            }
        }
    }
    &.sticky {
        top: 0;
        position: fixed;
        z-index: 100;
        width: 100%;
    }
}

.step-columns {
    .step {
        color: #5764c6;
        font-weight: bold;
        font-size: 150%;
        text-transform: uppercase;
        p {
            -ms-transform: translateY(0%);
            -webkit-transform: translateY(0%);
            transform: translateY(0%);
        }
    }
    p {
        // margin: 0;
        // position: relative;
        // top: 50%;
        // -ms-transform: translateY(24%);
        // -webkit-transform: translateY(24%);
        // transform: translateY(24%);
        &:not(.number) {
            margin-top: 0.4rem;
        }
    }
}

.divider {
    margin-left: 0;
    margin-right: 0;
}

.info {
    margin: 0;
    .preamble {
        // .quattrocento();
        font-size: 3rem;
        margin: 20px 0 0 0;
        font-weight: 300;
    }
    h1 {
        margin: 20px 0;
        margin-top: 10px;
    }
}

.signup {
    margin: 2rem 0;
}

.info-block {
    color: #888;
}

.help-block {
    color: #bbb;
    font-size: 95%;
    font-style: italic;
    margin: 5px 0;
}

.wrapper {
    min-height: 100%;
	margin: 0 auto -40px;
}

.push {
    height: 40px;
}

.footer {
    height: 40px;
    padding: 10px 0;
    background-color: @colorEko;
    color: #fff;
    p {
        margin: 0;
    }
    a {
        color: #fff;
        &:hover {
            color: #fff;
        }
    }
}

.table {
    tr {
        &.strike-through {
            text-decoration: line-through;
        }
    }
    th {
        &.text-right {
            text-align: right;
        }
    }
    td {
        &.text-right {
            text-align: right;
        }
        .validation-error {
            position: absolute;
            margin-top: -2px;
            margin-left: 5px;
        }
    }
    &.table-compact {
        th {
            padding: 0.5rem 0;
        }
        td {
            padding: 0.5rem 0;
        }
    }
}

// Helpers
.m-top-20 {
    margin-top: 20px;
}

.mb-20 {
    margin-bottom: 2rem;
}

.validation-error {
    color: @control-color-danger;
    .text-italic();
}

.red {
    color: @control-color-danger;
}

a {
    &.red {
        color: @control-color-danger;
        &:hover, &:focus {
            color: darken(@control-color-danger, 10%);
        }
    }
}

.bordered {
    border: 2px solid #eee;
    padding: 0 2rem 1rem 2rem;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
}

.required-info {
    font-style: italic;
    color: #aaa;
    span {
        &.required {
            color: @control-color-danger;
            font-size: 100%;
            font-weight: bold;
            vertical-align: top;
            margin-left: 6px;
        }
    }
}

label {
    &.required {
        &::after {
			content: "*";
			color: @control-color-danger;
			font-size: 100%;
            font-weight: bold;
			vertical-align: top;
			margin-left: 6px;
		}
    }
}

.label {
    &.label-not_registered {
        background: @control-color-danger;
        border-color: darken(@control-color-danger, 5%);
        color: #fff;
    }
    &.label-completed {
        background: @control-color-success;
        border-color: darken(@control-color-success, 5%);
        color: #fff;
    }
    &.label-registered {
        background: @core-color;
        border-color: darken(@core-color, 5%);
        color: #fff;
    }
    &.label-in_progress {
        background: @control-color-info;
        border-color: darken(@control-color-info, 5%);
        color: #fff;
    }
}

.startpage {
    h3 {
        font-weight: normal;
    }
    .card .card-image:first-child img {
        .circle();
        max-width: 100px;
        margin: 0.5rem auto;
        &.not-round {
            border-radius: 0;
            max-width: 100%;
            margin: auto;
        }
    }
    .bg {
        padding: 30px 0 65px 0;
        background-color: #fff;
        &:nth-child(even) {
            background-color: lighten(#f7f4e9, 4%);
        }
    }
    .push {
        height: 0 !important;
    }
}

// Infographics/charts
circle {
  fill: yellowgreen;
  stroke: #655;
  stroke-width: 32;
  stroke-dasharray: 38 100; /* for 38% */
}

// Responsive fixes
.columns {
    @media screen and (max-width: 600px) {
        padding: 0 15px;
        &.no-p {
            padding: 0;
        }
    }
}

.nav {
    background-color: #eee;
    padding: 2px 0;
    p {
        margin: 0;
    }
}

.quote {
    font-size: 1.8rem;
    // color: #555;
    // font-style:italic;
    text-align: center;
    p {
        padding-top: 7px;
        line-height: 2.5rem;
    }
    // p:before {
    //     // content: "\201D";
    //     font-size: 6rem;
    //     vertical-align: middle;
    //     display: inline-block;
    //     margin-bottom: -3px;
    //     margin-right: 5px;
    //     color: #ddd;
    //     font-family: serif;
    // }
}

.slider-results {
    margin-top: 20px;
    h5 {
        line-height: 1.2;
    }
}

.slider-segment {
    width: 100%;

    label {
        font-weight: bold;
        display: block;
        margin-bottom: 5px;
        margin-top: 20px;
    }

    .slider {
        -webkit-appearance: none;
        width: 100%;
        height: 15px;
        border-radius: 5px;
        background: #d3d3d3;
        outline: none;
        opacity: 0.7;
        -webkit-transition: .2s;
        transition: opacity .2s;
        &:hover {
            opacity: 1; /* Fully shown on mouse-over */
        }
        &::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            width: 25px;
            height: 25px;
            border-radius: 50%;
            background: darken(#b1cca7, 20%);
            cursor: pointer;
        }
        &::-moz-range-thumb {
            width: 25px;
            height: 25px;
            border-radius: 50%;
            background: darken(#b1cca7, 20%);
            cursor: pointer;
        }
    }
}

.text-centered {
    text-align: center;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.color-green {
    color: @colorEko;
}
