// Avatars
.avatar {
  border-radius: 50%;
  display: inline-block;
  font-size: 1.4rem;
  font-weight: 300;
  height: 3.2rem;
  line-height: 1;
  margin: 0;
  position: relative;
  vertical-align: middle;
  width: 3.2rem;

  &.avatar-xs {
    font-size: .8rem;
    height: 1.6rem;
    width: 1.6rem;
  }
  &.avatar-sm {
    font-size: 1rem;
    height: 2.4rem;
    width: 2.4rem;
  }
  &.avatar-lg {
    font-size: 2rem;
    height: 4.8rem;
    width: 4.8rem;
  }
  &.avatar-xl {
    font-size: 2.6rem;
    height: 6.4rem;
    width: 6.4rem;
  }

  img {
    border-radius: 50%;
    height: 100%;
    width: 100%;
  }

  .avatar-icon {
    background: #fff;
    bottom: -.2em;
    height: 50%;
    padding: 5%;
    position: absolute;
    right: -.2em;
    width: 50%;
  }

  &[data-initial]::after {
    color: @core-light-color;
    content: attr(data-initial);
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
