// Badges
.badge {
  position: relative;

  &[data-badge]::after {
    background: @core-color;
    background-clip: padding-box;
    border: .1rem solid #fff;
    border-radius: 1rem;
    color: @core-light-color;
    content: attr(data-badge);
    display: inline-block;
    font-size: 1.1rem;
    height: 1.8rem;
    line-height: 1.2rem;
    min-width: 1.8rem;
    padding: .2rem .5rem;
    text-align: center;
    transform: translate(-.2rem, -.8rem);
    white-space: nowrap;
  }
}
