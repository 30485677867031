// Navbar
.navbar {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 1rem;

  .navbar-brand {
    font-size: 2rem;
    text-decoration: none;
    vertical-align: middle;
  }
}